.loading-bg {
  overflow: hidden;
  height: 100vh;
}

.bg-custom-gradient {
  background: rgb(21, 68, 155);
  background: linear-gradient(135deg, rgba(21, 68, 155, 1) 0%, rgb(17, 86, 214) 100%);
}

.text-custom {
  color: rgb(21, 68, 155);
}

.cursor-pointer {
  cursor: pointer;
}

a.nav-icon {
  color: #6c757d;

  &:hover {
    color: rgb(21, 68, 155);
  }
}

.w-100px {
  width: 100px;
}

.w-110px {
  width: 110px;
}

.btn.show-status {
  border-radius: 1rem;
  width: 155px;

  span {
    flex-grow: 1;
    text-align: center;
  }
}

.btn.btn-delete-item {
  top: 0.75rem;
  left: 8rem;
  z-index: 10;
}

tr.error-row {
  background-color: #fed6dd;

  td {
    box-shadow: none;
  }
}

body>.bootstrap-datetimepicker-widget {
  z-index: 2000;

  table {

    th {
      padding: 0.35rem 0.5rem;
      font-size: 1rem;
      font-weight: 600;
    }

    td {
      padding: 0.35rem 0.5rem;
      font-weight: 500;

      span {
        padding: 0.35rem 0.5rem;
        height: 40px;
        line-height: 32px;
      }
    }
  }
}

.bootstrap-select {
  .dropdown-menu {
    border: 1px solid #ccc;

    .popover-header {
      text-align: end;

      .close {
        color: #f93154;
        background-color: #f93154;
        text-transform: uppercase;
        vertical-align: bottom;
        border: 0;
        border-radius: 50rem;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
        font-weight: 500;
        padding: 0.625rem 1rem 0.5rem;
        font-size: 0.75rem;
        line-height: 1.5;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

        &:before {
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          color: #fff;
          content: '\f057';
        }

        &:after {
          color: #fff;
          content: 'Close';
        }
      }
    }
  }

  &.show-tick {
    .dropdown-menu {
      .selected span.check-mark {
        left: 15px;
        right: unset;
      }

      li a span.text {
        margin-left: 20px;
        margin-right: 0;
      }
    }
  }

  .bs-searchbox .form-control {
    width: 100%;
    margin: 0;
    border: 1px solid #ccc !important;
  }
}

#client-section,
#admin-section {

  .bootstrap-datetimepicker-widget,
  .custom-datatable .bootstrap-datetimepicker-widget {
    table {

      th {
        padding: 0.35rem 0.5rem;
        font-size: 1rem;
        font-weight: 600;
      }

      td {
        padding: 0.35rem 0.5rem;
        font-weight: 500;

        span {
          padding: 0.35rem 0.5rem;
          height: 40px;
          line-height: 32px;
        }
      }
    }
  }
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: unset;

  a[data-action="togglePicker"]>span.fa.fa-clock-o::before {
    content: "\f017";
    font-weight: 900;
  }

  .timepicker-picker {
    a.btn {
      box-shadow: none !important;
    }
  }

  .picker-switch td {
    padding: 0 !important;

    span {
      font-size: 24px !important;
      line-height: 28px !important;
      height: 40px !important;
    }
  }
}

.form-outline .form-control[readonly] {
  background-color: #fff;
}

input.form-control {
  font-weight: 500;
}

.rounded-pill {
  border-radius: 50rem;
}

.rounded-1rem {
  border-radius: 1rem;
}

.rounded-top-1rem {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.rounded-left-1rem {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.rounded-right-1rem {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.form-outline .form-control~.form-notch .form-notch-leading {
  width: 1rem !important;
}

.form-outline .form-control~.form-label {
  left: 1.2rem;
}

INPUT:-webkit-autofill,
SELECT:-webkit-autofill,
TEXTAREA:-webkit-autofill {
  border-radius: 10rem;
}

.form-outline .form-control {
  ~.form-label {
    max-width: 95%;
  }
}

.form-outline-static {
  position: relative;

  .form-control {
    min-height: auto;
    padding: 0.33em 0.75em;
    border: 0;
    background: transparent;
    transition: all 0.2s linear;

    ~.form-notch {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      text-align: left;
      pointer-events: none;

      div {
        pointer-events: none;
        border: 1px solid #bdbdbd;
        box-sizing: border-box;
        background: transparent;
        transition: all 0.2s linear;

        &.form-notch-leading {
          left: 0;
          top: 0;
          height: 100%;
          width: 1rem;
          border-right: none;
          border-radius: 0.25rem 0 0 0.25rem;
        }

        &.form-notch-middle {
          flex: 0 0 auto;
          width: auto;
          max-width: calc(100% - 1rem);
          height: 100%;
          border-top: none;
          border-right: none;
          border-left: none;
        }

        &.form-notch-trailing {
          flex-grow: 1;
          height: 100%;
          border-left: none;
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }

    ~.form-label {
      position: absolute;
      top: 0;
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      left: 1.2rem;
      padding-top: 0.37rem;
      pointer-events: none;
      transform-origin: 0 0;
      transition: all 0.2s ease-out;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0;
    }

    &.active~.form-label {
      transform: translateY(-1rem) translateY(0.1rem) scale(0.8);
    }

    &.is-invalid {
      ~.form-label {
        color: #f93154;
      }

      ~.form-notch {

        .form-notch-leading,
        .form-notch-middle,
        .form-notch-trailing {
          border-color: #f93154;
        }
      }
    }
  }
}

.loader-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;

  .loader-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.login-area {
  max-width: 420px;
  padding: 15px;

  .btn-toggle-password {
    top: 3px;
    right: 0;
    height: 33px;
    padding: 1px 6px;
    background-color: transparent;
    border-color: transparent;
    color: #747474;
    box-shadow: none !important;
    outline: none !important;
    z-index: 1000;

    &:hover {
      color: #1266f1;
    }

    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  .alert-container {
    height: 0;

    &.show-alert {
      height: inherit;
    }

    i {
      font-size: 25px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.sidebar {
  min-width: 220px;
  max-width: 220px;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  direction: ltr;
}

.sidebar-content {
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.sidebar-brand {
  font-weight: 600;
  font-size: 1.15rem;
  padding: 1.15rem 1.5rem;
  display: block;
  color: #f8f9fa;
}

.sidebar-nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-grow: 1;

  .sidebar-header {
    background: transparent;
    padding: 1.5rem 1.5rem 0.375rem;
    font-size: 0.75rem;
    color: #ced4da;
  }

  li.sidebar-item {
    &.active {
      .sidebar-link {
        background: rgba(255, 255, 255, 1);
        color: rgb(21, 68, 155);
      }
    }

    .sidebar-link {
      text-transform: none;
      font-size: 0.875rem;
      line-height: 1.5;
      background: transparent;
      color: rgba(255, 255, 255, 0.9);
      padding: 0.625rem 1rem;
      margin: 0.5rem;
      border: 2px solid transparent;

      &:hover {
        border: 2px solid #fff;
      }

      i {
        width: 20px;
        text-align: center;
        font-size: 1rem;
      }
    }
  }
}

.main {
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 100vh;
  transition: margin-left 0.35s ease-in-out, left 0.35s ease-in-out, margin-right 0.35s ease-in-out, right 0.35s ease-in-out;
  background: #f5f7fb;
  flex-direction: column;
  overflow: hidden;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.navbar-fixed-container {
  width: 100%;
  height: 66px;

  .navbar {
    position: fixed;
    z-index: 1;
    padding-top: 0.75rem;
    padding-right: 1.375rem;
    padding-bottom: 0.75rem;
    padding-left: 1.375rem;
  }
}

.sidebar.js-sidebar {
  margin-left: 0;

  +.main .navbar-fixed-container .navbar {
    width: calc(100% - 220px);
    transition: width .35s ease-in-out;
  }

  &.collapsed {
    margin-left: -220px;

    +.main .navbar-fixed-container .navbar {
      width: 100%;
    }
  }
}

.sidebar-toggle {
  cursor: pointer;
  width: 26px;
  height: 26px;
  display: flex;
  margin-right: 1rem;
}

.hamburger,
.hamburger:before,
.hamburger:after {
  position: relative;
  cursor: pointer;
  border-radius: 1px;
  height: 3px;
  width: 24px;
  background: #495057;
  display: block;
  content: "";
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
}

.hamburger:before {
  top: -7.5px;
  width: 24px;
  position: absolute;
}

.hamburger:after {
  bottom: -7.5px;
  width: 16px;
  position: absolute;
}

.custom-datatable {

  label.custom-search input,
  label.custom-search select {
    margin: 5px 0 0 0.5em;
  }

  .table-responsive {
    min-height: 150px;
  }

  table.table {
    margin: 0;

    &.report-table {
      button {
        height: 35px;
      }
    }

    th {
      padding: 10px;
      font-size: 0.9rem;
      line-height: 1.2;
      font-weight: 600;
      cursor: default;

      &.with-sort {
        padding-right: 20px;

        a {
          font-size: 0.9rem;
          line-height: 1.2;
          color: #fff;

          &:focus {
            color: #fff;
          }

          &:hover {
            color: #faf3c5;
            text-decoration: none;
          }

          svg {
            position: absolute;
            right: 2px;
            bottom: 10px;
          }
        }
      }
    }

    tr {
      td {
        font-weight: 500;
        padding: 0.75rem;
        vertical-align: middle;
      }
    }
  }

  ul.pagination {
    flex-wrap: wrap;
    justify-content: end;

    .page-item {
      cursor: pointer;
      margin: 0.25rem 0;

      .page-link {
        border: 1px solid #ccc;
        border-radius: 0;
      }

      &.active .page-link {
        z-index: 0;
      }

      &.disabled {
        cursor: not-allowed;

        .page-link {
          background-color: #eee;
        }
      }

      &:first-child .page-link {
        border-top-left-radius: 50rem;
        border-bottom-left-radius: 50rem;
      }

      &:last-child .page-link {
        border-top-right-radius: 50rem;
        border-bottom-right-radius: 50rem;
      }
    }
  }
}

#createOrderFormTable .custom-datatable,
#editOrderModal .custom-datatable {
  td {
    input.form-control {
      font-size: 0.85rem !important;
    }

    .dropdown-bs-select-area .bootstrap-select {
      margin-top: -0.5rem;

      .dropdown-toggle {
        .filter-option-inner-inner {
          font-size: 0.85rem;
        }
      }
    }

    .btn-floating.btn-delete-item-table {
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --mdb-table-accent-bg: rgba(17, 86, 214, 0.15);
  color: var(--mdb-table-striped-color);
}

.dropdown-bs-select-area {
  .bootstrap-select {
    .dropdown-toggle {
      &.btn-select-custom {
        border: none;
        background-color: #fff;
        box-shadow: none;
        font-size: 1rem;
        text-transform: none;
        color: #4f4f4f !important;
      }

      &:focus {
        outline: none !important;
      }
    }

    .dropdown-menu.show {
      transform: none !important;

      a.dropdown-item {

        &.active,
        &.selected,
        &:hover {
          background-color: #dce6f9;
        }
      }
    }

    &.dropup .dropdown-toggle:after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }

  &.limit-control-container .bootstrap-select {
    width: 80px !important;
  }

  &.client-control-container .bootstrap-select {
    width: 100% !important;
  }
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  top: 10px;
}

.accordion-button.custom-accordion-button,
.accordion-button.custom-accordion-button:after {
  color: #4f4f4f;

  &:hover,
  &:focus,
  &:not(.collapsed) {
    color: #4f4f4f;
  }
}

#accordion-additional-attributes {
  .accordion-button {
    color: #fff;
    background-color: #00b74a;
    padding: 0.8rem 1rem;

    i {
      font-size: 1.2rem;
      transform: translateY(-1px);
    }
  }
}

.bootstrap-tagsinput {
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0.5rem 1rem 0.25rem;
  font-size: 1rem;
  line-height: 1.6;

  .tag {
    background: rgb(221, 233, 255);
    color: #4f4f4f;
    font-weight: 500;
    border-radius: 50rem;
    padding: 0.15rem 0.5rem 0.15rem 0.75rem;

    span {
      color: #f93154;
    }
  }
}

//scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgb(230, 235, 240);
}

::-webkit-scrollbar-thumb {
  background: rgba(17, 86, 214, 0.7);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(21, 68, 155, 0.9);
}

@media (min-width: 1px) and (max-width: 991.98px) {
  .sidebar.js-sidebar {
    margin-left: -220px;

    +.main .navbar-fixed-container .navbar {
      width: 100%;
    }

    &.collapsed {
      margin-left: 0;
    }
  }
}

@media (min-width: 1px) and (max-width: 575.98px) {
  .d-sm-none.dropdown-toggle:after {
    display: none;
  }

  .navbar-fixed-container .navbar {
    padding: 0.75rem;
    height: 64px;
  }
}

.spinner-loading-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;

  .spinner-border {
    width: 5rem;
    height: 5rem;

    &.small {
      width: 1rem;
      height: 1rem;
      border-width: 0.15em;
    }
  }

  .spinner-loading-text {
    color: #fff;
    font-size: 24px;

    &.small {
      font-size: 14px;
    }
  }
}

.modal .spinner-loading-container {
  background: #666;
}

// file upload
.box {
  position: relative;
  background: #ffffff;
  width: 100%;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid #9cbeff;
  margin-bottom: 10px;
}

.box-tools {
  position: absolute;
  right: 0;
  top: 8px;
}

.dropzone-wrapper {
  background-color: #edf2fc;
  border: 2px dashed #9cbeff;
  border-radius: 0.5rem;
  color: #212529;
  position: relative;
  height: 150px;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 80%;
  top: 50px;
  font-size: 16px;

  i {
    font-size: 2rem;
    color: #3e7df3;
  }

  p {
    font-weight: 500;
  }
}

.dropzone,
.dropzone:focus {
  position: absolute;
  top: 0;
  left: 0;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #dcE6f9;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;

  ul,
  ol {
    word-break: break-all;

    li {
      font-weight: 500;
    }
  }
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

#updateUsernameForm {
  h4 {
    color: #fff;
    position: absolute;
    top: -18px;
  }
}

#process-additional {
  ul {
    padding-left: 20px;
    font-weight: 600;
  }
}

@media (min-width: 576px) {
  .custom-datatable label.custom-search input {
    width: 150px;
  }
}

@media (min-width: 992px) {
  #updateUsernameForm {
    .detail-info {
      min-height: 172px;
    }
  }
}

@media (min-width: 1200px) {
  .custom-datatable label.custom-search input {
    width: 200px;
  }
}

.tokenize {
  ul.tokens-container.form-control {
    border-radius: 1rem;
    margin-bottom: 0;
    padding: 0;
		box-shadow: none;

		.placeholder {
      margin: 0 5px 0 0;
			background-color: initial;
			cursor: text;
      color: #555;
		}

    li {
      &.token {
        border-radius: 1rem;
        margin: 5px 5px 5px 0;
        padding-left: 0.5rem;
        color: #4f4f4f;
        font-weight: 500;
        border-color: #9cbeff;

        a.dismiss {
          color: #f93154;
          font-weight: 700;
          line-height: 1.6;
        }
      }

      &.token-search {
        margin: 0 5px 0 0;
      }
    }
  }
}

.tokenize-dropdown.dropdown ul.dropdown-menu li {
  &.active,
  &:hover,
  &:active,
  &:focus {
    background-color: #dce6f9 !important;
  }
}

.btn-secondary {
	background-color: #777 !important;

	&:hover {
		background-color: #666 !important;
	}
}

.input-block {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 1rem;
}

.ui-menu {
	border: 0 !important;
	border-radius: 0.5rem;
	box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.4), 0 10px 20px -2px rgba(0, 0, 0, 0.1);
	font-size: 0.875rem;

	.ui-menu-item {
		padding: 0;

		&:first-child {
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;

			.ui-menu-item-wrapper {
				border-top-left-radius: 0.5rem;
				border-top-right-radius: 0.5rem;
			}
		}

		&:last-child {
			border-bottom-left-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;

			.ui-menu-item-wrapper {
				border-bottom-left-radius: 0.5rem;
				border-bottom-right-radius: 0.5rem;
			}
		}

		.ui-menu-item-wrapper {
			padding: 0.5rem 1rem;

			&.ui-state-active {
				border: 1px solid #dce6f9;
				background: #dce6f9;
				color: #000;
			}
		}
	}
}